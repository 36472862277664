import request from '@/api/request.js'

// 供应商统计（团长端）
export function getDataList (data) {
  return request({
    url: '/api/stat/retailer/chain',
    method: 'POST',
    data
  })
}
// 刷新统计数据（团长端）
export function getRefreshDatas (data) {
  return request({
    url: '/api/stat/retailer/refresh',
    method: 'POST',
    data
  })
}
// 销售统计(团长端)
export function getSaleDatas (data) {
  return request({
    url: '/api/stat/retailer/sales',
    method: 'POST',
    data
  })
}
// 销售统计(团长端)
export function getTokens (data) {
  return request({
    url: '/api/user/token',
    method: 'POST',
    data
  })
}
// 数据分析子菜单
export function getMenuOfChildren (data) {
  return request({
    url: '/api/bi/menuOfChildren',
    method: 'POST',
    data
  })
}