<template>
  <div>
    <div class="contents">
     

    <div class="mainbox">
   
     <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column prop="day" label="日期" width="160"></el-table-column>
        <el-table-column prop="gmv" label="销售额" width="100" header-align="center" align="center"></el-table-column>
         <el-table-column prop="goodsCount" label="订单商品数" width="100" header-align="center" align="center"></el-table-column>
         <el-table-column prop="unitPrice" label="客单价" header-align="center" align="center"></el-table-column>
         <el-table-column prop="takeRate" label="毛利" header-align="center" align="center"></el-table-column>
        <el-table-column prop="h24HourHasTimeline" label="24H发货揽收率" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column prop="h24HourDelivery" label="24H发货率" width="100" header-align="center" align="center"></el-table-column>
         <el-table-column prop="orderCount" label="订单量" header-align="center" align="center"></el-table-column>
         <el-table-column prop="hasOrderSupplierCount" label="有订单供应商数量" width="100" header-align="center" align="center"></el-table-column>
         <el-table-column prop="newAddSupplierCount" label="新增供应商数量" header-align="center" align="center"></el-table-column>
         <el-table-column prop="sendSupplierOrderCount" label="交单供应商订单数" header-align="center" align="center"></el-table-column>
         <el-table-column prop="skuCount" label="动销商品数" header-align="center" align="center"></el-table-column>
      </el-table>
      <div class="paginations">
           <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      
      </div>

    </div>
  </div>
</template>

<script>
import { getDataList, getRefreshDatas, getSaleDatas } from "@/api/datas";
import { MessageBox } from 'element-ui'
let moment = require('moment');
export default {
  name: 'Orderss',
  data() {
    return {
      searchForm: {
        date: '', 
        skintype: '',
        goodsName: '',
        buyerMobile: '',
        buyerName: '',
        status: '',
        start: '',
        end: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      kuaiDiflag: false,
      loadingFile: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTopSearch(){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      getDataList(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          nodataArr.map((item)=>{
            item.xiadanTime = moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          })
          that.tableData = nodataArr
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleClose(){
      this.kuaiDiflag = false;
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    daochu(){
      console.log('eee')
      var that = this
       MessageBox.alert('您是否确认要导出？', {
            confirmButtonText: '立即导出',
            type: 'warning'
          }).then(() => {
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.start = that.searchForm.date ? moment(that.searchForm.date[0]).format('YYYY-MM-DD') : '';
      datas.end = that.searchForm.date ? moment(that.searchForm.date[1]).format('YYYY-MM-DD') : '';
      this.loadingFile = true
      exportOrder(datas).then((res) => {
        if (res.code === 200) {
          that.loadingFile = false
          let link = document.createElement('a')
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

        } else {
          this.$message({
            type: 'warning',
            message: '无记录可导出'
          })
        }
      })
          })
    },
    piliangdaochu(){
      var that = this
      if(that.multipleSelection.length==0){
        this.$message({
            type: 'warning',
            message: '请选择要导出的订单'
          })
        return
      }
      var datas = that.multipleSelection
      this.loadingFile = true
      exportOrder(datas).then((res) => {
        if (res.code === 200) {
          that.loadingFile = false
          let link = document.createElement('a')
          link.href = res.data
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

        } else {
          this.$message({
            type: 'warning',
            message: '无记录可导出'
          })
        }
      })
    },
    sendGood(){
      var that = this
      that.kuaiDiflag = true;
      // upDelivery(datas).then((res) => {
      //   if (res.code === 200) {

      //   } else {
      //     this.$message({
      //       type: 'warning',
      //       message: '无记录可导出'
      //     })
      //   }
      // })
    },
    handleCurrentChange(){
      
    },
    UploadImage(param){
      const formData = new FormData()
      formData.append('file', param.file)
      upDelivery(formData).then(res => {
      	console.log('上传图片成功')
        param.onSuccess() 

        if(res.code === 200){
          that.kuaiDiflag = false
        
        }

      })
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.caozuoqu{
  float:right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
</style>